import * as types from '@/store/mutation-types'

export const state = {
  status: false,
  tooltip: null
}
export const getters = {
  status: state => state.status,
  tooltip: state => state.tooltip
}

export const mutations = {
  [types.SET_POPUP_STATUS] (state, payload) {
    state.status = payload
  },
  [types.SET_TOOLTIP] (state, payload) {
    state.tooltip = payload
  }
}
export const actions = {
  setPopup ({ commit }, payload) {
    commit(types.SET_POPUP_STATUS, payload.status)
    commit(types.SET_TOOLTIP, payload.tooltip)
  },
  togglePopup ({ commit }, payload) {
    commit(types.SET_POPUP_STATUS, payload.status)
  }
}
