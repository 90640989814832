import _ from 'lodash'
import VisibilityHandler from './handlers/visibility'
import StepSelectedLabelHandler from './handlers/step-selected-labels'
import QuantityHandler from './handlers/quantity'
import PricesHandler from './handlers/prices'
import DiscountsHandler from './handlers/discounts'
import DisabledConditionalOptions from './handlers/disabled-conditional-options'
import PreviewImages from './handlers/preview-images'
import ExampleImages from './handlers/example-images'
import Serializer from './serializer'

const Configurator = class {
  constructor (configurator) {
    this.clone = false
    this.id = configurator.config_id
    this.image = configurator.image || null
    this.name = configurator.name
    this.description = configurator.description
    this.quantity = configurator.quantity || 1
    this.steps = configurator.steps || []
    this.exampleImages = configurator.example_images || {}
    this.imagesData = configurator['image-manager'].images || []

    if (!this.clone) {
      this.serializer = new Serializer(this)
    }

    this.price = configurator.price = { total: 0 }
    this.discount = Number.parseFloat(configurator.discount) || 0
    this.discount_conf = Number.parseFloat(configurator.discount) || 0
    this.previewImages = []
    this.config = configurator.config || []
    this.threeds = configurator.threeds

    this.handlers = {
      visibility: new VisibilityHandler(this),
      quantity: new QuantityHandler(this),
      stepSelectedLabel: new StepSelectedLabelHandler(this),
      prices: new PricesHandler(this),
      discounts: new DiscountsHandler(this),
      disabledConditionalOptions: new DisabledConditionalOptions(this),
      previewImages: new PreviewImages(this),
      exampleImages: new ExampleImages(this)
    }

    this.updatePriceStructure()
    this.update()
  }

  summary () {
    return {
      id: this.id,
      main_image: this.image,
      name: this.name,
      price: this.price,
      steps: this.getSummaryActiveSteps().filter(s => s.visible).map(step => {
        const optionDescription = []
        const detail = []
        if (step.type === 'dimension') {
          detail.push({ name: step.selectedLabel, total: 0, discounted: 0, discount: 0 })
        }
        // find color
        if (step.type === 'color') {
          const color = step.colors.find(c => c.selected)
          detail.push({
            name: step.selectedLabel,
            total: step.price.total,
            discounted: 0,
            discount: (color.discount > 0) ? color.discount : 0
          })
        }
        if (step && step.options) {
          const option = step.options.find(o => o.selected)
          if (option && option.print_description) {
            optionDescription.push(option.print_description)
          }
          // Get item name and price
          const option2 = step.options.filter(o => o.selected && o.visible)
          if (step.model_step) {
            option2.forEach(o => {
              // let discTotal = (this.getDimension() * o.discount / 100);
              detail.push({
                name: o.name,
                // total: this.getDimension(),
                total: Math.ceil(o.price.total),
                // discounted: this.getDimension() - discTotal,
                discounted: Math.ceil(o.price.discounted),
                discount: o.discount
              })
            })
          } else {
            option2.forEach(o => {
              if (o && o.height) {
                detail.push({ name: o.width + 'cm x ' + o.height + 'cm', total: o.price.total, discounted: 0, discount: 0 })
              } else {
                detail.push({ name: o.name, total: o.price.total, discounted: o.price.discounted, discount: o.discount })
              }
            })
          }
        }
        return {
          name: step.name,
          printDescription: step.printDescription,
          optionDescription: optionDescription,
          value: step.selectedLabel,
          price: step.price.total,
          type: step.type,
          option: detail
        }
      })
    }
  }

  openStep (id) {
    const step = this.getActiveSteps().find(s => s.id === id)
    if (step) {
      step.opened = !step.opened
    }
  }

  /**
   * Get dimension price
   */
  getDimension () {
    const dim = this.getActiveSteps().find(s => s.type === 'dimension_glass')
    if (dim) {
      return dim.price.total
    }
  }

  updatePriceStructure () {
    this.steps.forEach((step) => {
      const price = Number.parseFloat(step.price || 0)
      step.price = {
        total: price,
        self: price
      }
      if (step.options) {
        step.options.forEach((option) => {
          const price = Number.parseFloat(option.price || 0)

          option.price = {
            total: price,
            self: price
          }
        })
      }
    })
    return this
  };

  getActiveSteps () {
    return this.steps.filter(step => step.visible)
  }

  getSummaryActiveSteps () {
    return this.steps.filter(step => step.visible && !step.nav_execute)
  }

  getSelectedOptions (stepId) {
    const step = this.steps.find(step => stepId === step.id)

    if (step) {
      return step.options.filter(option => option.selected)
    }

    return null
  }

  getOptions (stepId) {
    return this.steps.find(step => step.id === stepId).options || []
  }

  selectColor ({ id: stepId }, { id: colorId }) {
    const step = this.steps.find(step => step.id === stepId)
    const selectedColor = step.colors.find(c => c.selected)
    const newColor = step.colors.find(c => c.id === colorId)

    if (selectedColor.id !== newColor.id) {
      selectedColor.selected = false
      newColor.selected = true
    }

    this.update()

    return this
  }

  setQuantity ({ stepId, value }) {
    this.quantity = value
    this.steps.find(s => s.id === stepId).value = value

    this.update()
  }

  setCountOption ({ stepId, optionId, value }) {
    const step = this.steps.find(s => s.id === stepId)
    const option = step.options.find(o => o.id === optionId)
    option.value = value
    option.selected = option.value > 0
    this.update()
  }

  selectOption ({ id: stepId }, { id: optionId }) {
    const step = this.steps.find(step => step.id === stepId)
    const option = step.options.find(option => option.id === optionId)
    if (option && !option.selected) {
      if (step.type === 'simpleselect' || step.type === 'simpletechnic') {
        // unselect all other selected options
        step.options.forEach((stepOption) => {
          if (option.id !== stepOption.id && stepOption.selected) {
            stepOption.selected = false
            stepOption.price.total = 0
          }
        })
      } else if (step.type === 'conditional' || step.type === 'glass' || step.type === 'color_roof' || step.type === 'color_screen') {
        // unselect all other selected options
        step.options.forEach((stepOption) => {
          if (option.id !== stepOption.id && stepOption.type === 'select' && stepOption.selected) {
            stepOption.selected = false
            stepOption.price.total = 0
          }
        })
      }
      option.selected = true
      // sub options
      if (option && option.sub_options) {
        if (!option.selected) {
          // console.log(option)
          option.sub_options.filter(o => {
            o.selected = false
          })
        } else {
          option.sub_options.filter(o => {
            o.selected = true
          })
        }
      }
    }

    this.update()

    return this
  }

  toggleOption ({ id: stepId }, { id: optionId }) {
    const step = this.steps.find(step => step.id === stepId)
    const option = step.options.find(option => option.id === optionId)
    option.selected = !option.selected
    if (option && option.sub_options) {
      const so = option.sub_options.find(o => o.selected)
      if (typeof so !== 'undefined') {
        option.selected = true
      }
    }
    this.update()
    return this
  }

  selectSubOption ({ stepId, optionId, subOptionId, e }) {
    const step = this.steps.find(s => s.id === stepId)
    if (typeof step !== 'undefined') {
      const option = step.options.find(o => o.id === optionId)
      if (option && option.sub_options) {
        const sub = option.sub_options.find(s => s.id === subOptionId)
        if (sub.type === 'check') {
          const val = e.target.checked
          sub.selected = val
          const so = option.sub_options.find(o => o.selected)
          if (typeof so === 'undefined') {
            option.selected = false
          } else {
            if (!option.selected) {
              option.selected = true
            }
          }
        } else {
          sub.selected = !sub.selected
        }
      }
    }
    this.update()
    return this
  }

  setDimension (step, type, value) {
    const currentStep = this.steps.find(s => s.id === step.id)
    if (currentStep) {
      const options = this.steps.find(s => s.model_step).options
      const option = options.find(o => o.selected)
      const inputVal = Number.parseInt(value)
      if (option && typeof option !== 'undefined') {
        if (type === 'width' ||
            type === 'length' ||
            type === 'height' ||
            type === 'height_additional') {
          // check min value
          const min = option.dimension[type].min
          if (inputVal <= min) {
            option.dimension[type].value = min
          } else {
            option.dimension[type].value = Number.parseInt(value)
          }
        }
      }
      // check min value
      if (inputVal <= currentStep[type].min) {
        currentStep[type].value = currentStep[type].min
      } else {
        currentStep[type].value = Number.parseInt(value)
      }
    }
    this.update()
    return this
  }

  setDimensionAwnings (step, type, value) {
    const currentStep = this.steps.find(s => s.id === step.id)
    if (currentStep) {
      const options = this.steps.find(s => s.model_step).options
      const option = options.find(o => o.selected)
      const inputVal = Number.parseInt(value)
      if (option && typeof option !== 'undefined') {
        if (type === 'width' || type === 'length') {
          // check min value
          const min = option.dimension[type].min
          if (inputVal <= min) {
            option.dimension[type].value = min
          } else {
            option.dimension[type].value = Number.parseInt(value)
          }
        }
      }
      // check min value
      if (inputVal <= currentStep[type].min) {
        currentStep[type].value = currentStep[type].min
      } else {
        currentStep[type].value = Number.parseInt(value)
      }
    }
    this.update()
    return this
  }

  getDiscount () {
    return this.discount
  }

  filteredImages () {
    const colorId = this.steps.find(step => step.type === 'color').colors.find(c => c.selected).id
    const images = this.imagesData
    return images.filter(image => {
      if (image.color_id === colorId || !image.color_id) {
        return image
      }
    })
  }

  getPreviewImages () {
    const previewImages = this.filteredImages()
    let filteredImages = previewImages.filter(image => {
      const option = this.getOption({
        stepId: image.step_id,
        optionId: image.option_id
      })
      let result = !!option && option.selected
      if (image.extra_option_ids) {
        image.extra_option_ids.forEach(optionId => {
          const o = this.getOption({ optionId })
          if (o) {
            result &= o.selected
          }
        })
      }
      return result
    })
    if (filteredImages.length === 0) {
      if (!this.image) {
        filteredImages = []
      } else {
        filteredImages = [{
          path: this.image
        }]
      }
    }
    filteredImages.sort(function (a, b) {
      return (a.order || 0) >= (b.order || 0)
    })
    return filteredImages
  }

  getExampleImages () {
    return this.handlers.exampleImages.process()
  }

  getOption ({ stepId, optionId }) {
    if (stepId) {
      const step = this.steps.find(step => step.id === stepId)
      if (step && step.options) {
        const option = step.options.find(option => option.id === optionId)
        return option
      }
    } else {
      let option

      this.steps.find(step => {
        option = (step.options || []).find(o => o.id === optionId)

        return !!option
      })
      return option
    }
  }

  getColor ({ stepId, optionId }) {
    if (stepId) {
      const step = this.steps.find(step => step.id === stepId)
      const option = step.colors.find(option => option.id === optionId)
      return option
    } else {
      let option

      this.steps.find(step => {
        option = (step.colors || []).find(o => o.id === optionId)

        return !!option
      })
      return option
    }
  }

  update () {
    this.handlers.visibility.process()
    this.handlers.quantity.process()
    this.handlers.disabledConditionalOptions.process()
    this.handlers.prices.process()
    this.handlers.discounts.process()

    if (!this.clone) {
      this.handlers.stepSelectedLabel.process()
    }

    return this
  }

  checkDimensionCaps () {
    const step = this.steps.find(s => s.model_step)
    const option = step.options.find(o => o.selected)
    if (option && option.dimension) {
      const w = Number.parseInt(option.dimension.width.value)
      let h = 0
      option.special_dim.filter(s => {
        if ((s.width.from <= Number.parseInt(w) && s.width.to >= Number.parseInt(w)) && s.price > 0) {
          h = s.height.to
        }
      })
      return { width: w, height: h }
    }
  }

  checkOptionPrice () {
    if (this.steps.find(s => s.type === 'dimension_glass')) {
      const step = this.steps.find(s => s.model_step)
      const option = step.options.find(o => o.selected)
      if (option && option.price.self === 0) {
        return true
      }
    }
  }

  getModelSelectOption () {
    const step = this.steps.find(s => s.model_step)
    const option = step.options.find(o => o.selected)

    return option.dimension
  }

  calculateTotalPrice ({ step, option }) {
    return new Promise((resolve, reject) => {
      const clone = _.cloneDeep(this)
      clone.clone = true
      clone.selectOption(step, option)
      this.getOption({ stepId: step.id, optionId: option.id }).price.all = clone.price
      resolve(clone.price)
    })
  }
}

export default Configurator
