import * as types from '../mutation-types'
import * as Routes from '~/lib/routes'
import Serializer from '~/lib/serializer'
import ConfiguratorClass from '~/lib/configurator'
import _ from 'lodash'
// state
export const state = {
  configurator: null,
  name: null,
  totalPrices: [],
  loading: true,
  usps: [
    '10 Jahre Garantie',
    'Feuerverzinkt',
    'Pulverbeschichtet',
    'Patentierten Pfostenantrieb',
    '80 verschiedene Muster',
    'Technische Meisterleistung',
    'Kostenlose Lieferung',
    'Freitragend und Wartungsfrei',
    'Sicher und Zuverlässig',
    'Alles aus einer Hand',
    'vor Ort Beratung und Aufmass',
    'europaweite Lieferung',
    'deutschlandweite Montage',
    'Sicherheit und Privatsphäre',
    'Zaunanlage nach Maß',
    'Best-Preis-Garantie'
  ],
  steps: [
    {
      id: null,
      visible: true,
      options: []
    }
  ],
  price: {
    discounted: 0,
    total: 0
  },
  discount: 0,
  animations: [],
  previewImagesLoading: true,
  previewImages: [],
  previewModalShow: false,
  exampleImages: [],
  exampleImagesLoading: true,
  exampleImageIndex: 0,
  currentStep: null,
  favorites: [],
  showFavorites: false,
  showRequestForm: false,
  ausleger: 150,
  threeLoading: true,
  backsideStatus: false,
  backsideBtn: false,
  dimGarage: null,
  alternativeVariants: null,
  dimensionCapsText: null,
  navigationMenu: [],
  config: []
}
// getters
export const getters = {
  favorites: state => state.favorites,
  name: state => state.name,
  usps: state => state.usps,
  ausleger: state => state.ausleger,
  threeLoading: state => state.threeLoading,
  backsideStatus: state => state.backsideStatus,
  backsideBtn: state => state.backsideBtn,
  steps: state => state.steps,
  dimGarage: state => state.dimGarage,
  navigation: state => {
    return state.steps.filter(s => !s.nav_execute || !s.visible)
  },
  price: state => state.price,
  discount: state => state.discount,
  animations: state => state.animations,
  previewImagesLoading: state => state.previewImagesLoading,
  previewImages: state => state.previewImages,
  previewModalShow: state => state.previewModalShow,
  loading: state => state.loading,
  exampleImages: state => state.exampleImages,
  exampleImagesLoading: state => state.exampleImagesLoading,
  exampleImageIndex: state => state.exampleImageIndex,
  currentStep: (state) => {
    return state.steps.find(step => step.id === state.currentStep) || state.steps.find(step => step.visible)
  },
  currentOptions: (state, getters) => {
    return (getters.currentStep.options || [])
      .filter(step => step.visible)
  },
  totalPrice: (state) => ({ id }) => {
    return state.totalPrices.find(o => o.id === id)
  },
  hasNextStep: (state) => {
    if (!state.currentStep && state.steps.length > 1) {
      return true
    }
    let found = false
    let hasNextStep = false
    state.steps.find(s => {
      if (found) {
        hasNextStep = true
        return true
      }
      found = s.id === state.currentStep
      return false
    })
    return hasNextStep
  },
  summary: state => {
    const summary = state.configurator ? state.configurator.summary() : {}
    return {
      ...summary,
      images: state.previewImages,
      multiple: true
    }
  },
  showRequestForm: state => state.showRequestForm,
  alternativeVariants: state => state.alternativeVariants,
  dimensionCapsText: state => state.dimensionCapsText,
  navigationMenu: state => state.navigationMenu,
  config: state => state.config,
  configuratorDescription: state => {
    if (state.configurator && state.configurator.description) {
      return state.configurator.description
    }
  }
}
// actions
export const actions = {
  calculatePriceAll ({ commit, state, getters }, { option }) {
    const args = { option, step: getters.currentStep }
    commit(types.SET_LOADING_PRICE_ALL, { ...args, status: true })
    // state.configurator.update()
    state.configurator.calculateTotalPrice(args).then((price) => {
      setTimeout(() => {
        commit(types.SET_PRICE_ALL, { ...args, price })
        commit(types.SET_LOADING_PRICE_ALL, { ...args, status: false })
      }, 0)
    })
  },
  setConfigurator ({ commit, state, getters }, data) {
    commit(types.SET_LOADING, true)
    const json = JSON.parse(data.definition)
    const configurator = new ConfiguratorClass(json)
    // eslint-disable-next-line no-unused-vars
    let definition
    // eslint-disable-next-line no-cond-assign
    if (definition = Routes.definition()) {
      const serialize = new Serializer(configurator)
      serialize.deserialize(definition)
    }
    commit(types.SET_CONFIGURATOR, { data: configurator })
    commit(types.SET_NAME, configurator.name)
    commit(types.SET_PRICE, configurator.price)
    commit(types.SET_DISCOUNT, configurator.discount)
    commit(types.SET_STEPS, configurator.getActiveSteps())
    // eslint-disable-next-line dot-notation
    commit(types.SET_ANIMATIONS, json['image-manager'].animations || [])
    commit(types.SET_CONFIG, configurator.config)

    state.steps.filter(s => s.price_all).forEach(s => {
      s.options.forEach(o => {
        state.totalPrices.push({ id: o.id, discounted: 0, total: 0, loading: false })
      })
    })
    refreshImages({ commit, state })
    updateUrl({ commit, state, getters })
    configurator.update()
    commit(types.SET_LOADING, false)
  },
  selectSubOption ({ commit, state, getters }, { optionId, subOptionId, e }) {
    state.configurator.selectSubOption({ stepId: getters.currentStepId, optionId: optionId, subOptionId: subOptionId, e: e })
    commit(types.SET_STEPS, state.configurator.getActiveSteps())
    commit(types.SET_PRICE, state.configurator.price)
    updateUrl({ commit, state, getters })
  },
  setCurrentStep ({ commit, state }, id) {
    commit(types.SET_CURRENT_STEP, id)
    state.configurator.openStep(id)
    commit(types.SET_PRICE, state.configurator.price)
  },
  setDimension ({ commit, state, getters }, { type, value }) {
    state.configurator.setDimension(getters.currentStep, type, value)
    const caps = state.configurator.checkDimensionCaps()
    const checkPrice = state.configurator.checkOptionPrice()
    if (caps && caps.length > 0) {
      setDimensionMessage({ commit, state, getters })
    } else {
      commit(types.SET_DIMENSION_CAPS_TEXT, null)
    }
    if (checkPrice) {
      setDimensionMessage({ commit, state, getters })
    }
    const discount = state.configurator.getDiscount()
    commit(types.SET_DISCOUNT, discount)

    const dim = Number.parseInt(value)
    if (type === 'width' && state.name === 'Schiebetore') {
      if (dim <= 350) {
        commit(types.UPDATE_AUSLEGER, 150)
      } else if (dim >= 360 && dim <= 400) {
        commit(types.UPDATE_AUSLEGER, 170)
      } else if (dim >= 410 && dim <= 550) {
        commit(types.UPDATE_AUSLEGER, 190)
      } else if (dim >= 560 && dim <= 600) {
        commit(types.UPDATE_AUSLEGER, 210)
      }
    }
    commit(types.SET_STEPS, state.configurator.getActiveSteps())
    commit(types.SET_PRICE, state.configurator.price)
    updateUrl({ commit, state, getters })
  },
  setDimensionAwnings ({ commit, state, getters }, { type, value }) {
    state.configurator.setDimensionAwnings(getters.currentStep, type, value)

    commit(types.SET_STEPS, state.configurator.getActiveSteps())
    commit(types.SET_PRICE, state.configurator.price)
    updateUrl({ commit, state, getters })
  },
  setAlternative ({ commit, state, getters }, { width, height }) {
    const params = { height, width, currentStepId: getters.currentStep.id }
    const data = state.configurator.selectAlternative(params)
    commit(types.SET_ALTERNATIVE_VARIANTS, data)
    commit(types.SET_PRICE, state.configurator.price)
  },
  setValue ({ commit, state, getters }, { option, value }) {
    const configurator = state.configurator
    configurator.setCountOption({ stepId: getters.currentStep.id, optionId: option.id, value: value })

    commit(types.SET_STEPS, configurator.getActiveSteps())
    commit(types.SET_PRICE, configurator.price)

    refreshImages({ commit, state })
    updateUrl({ commit, state, getters })
  },
  selectOption ({ commit, state, getters }, option) {
    const configurator = state.configurator
    configurator.selectOption(getters.currentStep, option)
    const checkPrice = configurator.checkOptionPrice()
    if (checkPrice) {
      const dim = configurator.steps.find(s => s.type === 'dimension')
      const dims = configurator.getModelSelectOption()
      configurator.setDimension(dim, 'width', dims.width.min)
      configurator.setDimension(dim, 'height', dims.height.min)
    }
    const discount = configurator.getDiscount()
    commit(types.SET_DISCOUNT, discount)
    commit(types.SET_STEPS, configurator.getActiveSteps())
    commit(types.SET_PRICE, configurator.price)

    refreshImages({ commit, state })
    updateUrl({ commit, state, getters })
  },
  selectColor ({ commit, state, getters }, option) {
    const configurator = state.configurator
    configurator.selectColor(getters.currentStep, option)

    commit(types.SET_STEPS, configurator.getActiveSteps())
    commit(types.SET_PRICE, configurator.price)
    commit(types.SET_PREVIEW_IMAGES, configurator.getPreviewImages())

    refreshImages({ commit, state })
    updateUrl({ commit, state, getters })
  },
  selectDimensionTable ({ commit, state, getters }, { height, width }) {
    const configurator = state.configurator
    configurator.selectDimensionTable({ height, width, stepId: getters.currentStep.id })

    commit(types.SET_PRICE, configurator.price)
    commit(types.SET_STEPS, configurator.getActiveSteps())
    updateUrl({ commit, state, getters })
  },
  selectDimensionGarageTable ({ commit, state, getters }, { height, width }) {
    const configurator = state.configurator
    const res = configurator.selectDimGarageTable({ height, width, stepId: getters.currentStep.id })
    configurator.update()

    commit(types.SET_DIM_GARAGE, res)
    commit(types.SET_PRICE, configurator.price)
    commit(types.SET_STEPS, configurator.getActiveSteps())
    updateUrl({ commit, state, getters })
  },
  toggleOption ({ commit, state, getters }, option) {
    const configurator = state.configurator
    configurator.toggleOption(getters.currentStep, option)
    if (getters.currentStep.name === 'Muster' || getters.currentStep.name === 'Form') {
      commit(types.SET_BACKSIDE_BTN, false)
      state.configurator.setBacksideOption(false)
    }
    commit(types.SET_PRICE, configurator.price)
    commit(types.SET_STEPS, configurator.getActiveSteps())

    refreshImages({ commit, state })
    updateUrl({ commit, state, getters })
  }
}
// mutations
export const mutations = {
  [types.SET_CONFIGURATOR] (state, { data }) {
    if (data) {
      state.configurator = data
    }
  },
  [types.SET_CURRENT_STEP] (state, id) {
    state.currentStep = id
  },
  [types.SET_STEPS] (state, steps) {
    state.steps = steps
  },
  [types.SET_NAME] (state, name) {
    state.name = name
  },
  [types.SET_CONFIG] (state, config) {
    state.config = config
  },
  [types.SET_ANIMATIONS] (state, animations) {
    state.animations = animations
  },
  [types.SET_PREVIEW_IMAGES] (state, images) {
    state.previewImages = images
  },
  [types.SET_PREVIEW_IMAGES_LOADING] (state, status) {
    state.previewImagesLoading = status
  },
  [types.SET_EXAMPLE_IMAGES] (state, images) {
    state.exampleImages = images
  },
  [types.SET_PREVIEW_MODAL_SHOW] (state, status) {
    state.previewModalShow = status
  },
  [types.SET_EXAMPLE_IMAGES_LOADING] (state, status) {
    state.exampleImagesLoading = status
  },
  [types.SET_EXAMPLE_IMAGE_INDEX] (state, index) {
    state.exampleImageIndex = index
  },
  [types.SET_PRICE_ALL] (state, { price, option }) {
    Object.assign(state.totalPrices.find(o => o.id === option.id), price)
  },
  [types.SET_LOADING_PRICE_ALL] (state, { status, option }) {
    state.totalPrices.find(o => o.id === option.id).loading = status
  },
  [types.SET_PRICE] (state, price) {
    state.price = price
  },
  [types.SET_DISCOUNT] (state, discount) {
    state.discount = discount
  },
  [types.SET_DIMENSION_CAPS_TEXT] (state, status) {
    state.dimensionCapsText = status
  },
  [types.UPDATE_AUSLEGER] (state, ausleger) {
    state.ausleger = ausleger
  },
  [types.SET_DIM_GARAGE] (state, arr) {
    state.dimGarage = _.cloneDeep(arr)
  },
  [types.SET_ALTERNATIVE_VARIANTS] (state, arr) {
    state.alternativeVariants = _.cloneDeep(arr)
  },
  [types.SET_LOADING] (state, status) {
    state.loading = status
  },
  [types.SET_NEXT_STEP] (state) {
    let step = false
    let found = false
    const steps = state.steps.filter(s => s.visible && !s.nav_execute)
    steps.find(s => {
      if (found) {
        step = s
        return true
      }

      found = s.id === state.currentStep || !state.currentStep
      return false
    })
    state.currentStep = step.id
  }
}
function updateUrl ({ state, getters }) {
  const serializer = new Serializer(state.configurator)
  history.replaceState({}, null, Routes.config(getters.name, serializer.serialize()))
}
function refreshImages (context) {
  refreshExampleImages(context)
  refreshPreviewImages(context)
}
function refreshExampleImages ({ commit, state }) {
  commit(types.SET_EXAMPLE_IMAGES_LOADING, true)
  commit(types.SET_EXAMPLE_IMAGES, state.configurator.getExampleImages())
  commit(types.SET_EXAMPLE_IMAGES_LOADING, false)
}
function refreshPreviewImages ({ commit, state }) {
  commit(types.SET_PREVIEW_IMAGES_LOADING, true)
  commit(types.SET_PREVIEW_IMAGES, state.configurator.getPreviewImages())
  commit(types.SET_PREVIEW_IMAGES_LOADING, false)
}
function setDimensionMessage ({ commit, state, getters }) {
  // get default dimension
  const dims = state.configurator.getModelSelectOption()
  state.configurator.setDimension(getters.currentStep, 'width', dims.width.min)
  state.configurator.setDimension(getters.currentStep, 'height', dims.height.min)
  commit(types.SET_DIMENSION_CAPS_TEXT, 'Die von Ihnen gewählte Maße ist leider nicht möglich, bitte wählen Sie eine andere Breite oder Höhe aus.')
}
